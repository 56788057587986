'use client'
import classNames from 'classnames'
import logger from 'loglevel'
import { Tooltip } from '~/components/Tooltip'
import { IOrder } from '~/models/Order.types'
import { IOrgUserPopulated } from '~/models/Org.types'

type Props = { collaborators: IOrder['collaborators']; orgMembers: IOrgUserPopulated[]; size?: number }

const OrderCollaboratorAvatars = ({ collaborators, orgMembers, size = 42 }: Props) => {
  return (
    <div className='flex items-center py-2'>
      {collaborators.map((collaborator, i) => {
        const orgMember = orgMembers.find(orgMember => orgMember.user._id === collaborator)
        if (!orgMember) {
          logger.warn('orgMember not found')
          return null
        }
        return (
          <div key={orgMember.user._id} className={classNames('relative', { '-ml-3': i > 0 })}>
            <Tooltip side='bottom' hint={orgMember.user.name}>
              <img
                src={orgMember.user.picture}
                alt={orgMember.user.name}
                width={size}
                height={size}
                className='rounded-full border-white border-2'
              />
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}
export default OrderCollaboratorAvatars
