import classNames from 'classnames'
import Image from 'next/image'

type Props = {
  user: {
    picture?: string
  }
  size?: number
  round?: boolean
  className?: string
}

const Avatar = ({ user, size = 36, round = true, className, ...props }: Props) => {
  return (
    <Image
      src={user.picture || '/assets/global/avatar.png'}
      alt='User Avatar'
      width={size}
      height={size}
      style={{ width: size, height: size }}
      className={classNames(className, 'object-cover', { 'rounded-full': round })}
      {...props}
    />
  )
}

export default Avatar
