'use client'

import logger from 'loglevel'
import React from 'react'
import { toast as showToast } from 'react-toastify'
import { ServiceErrorResponse } from '~/services/_utils/ServiceError'

export class ApiError extends Error {
  statusCode: number
  code: string
  userMessage: string
  devMessage?: string
  data?: object

  constructor(
    {
      code,
      userMessage,
      devMessage,
      data,
      statusCode,
    }: {
      statusCode: number
      code: string
      userMessage: string
      devMessage?: string
      data?: object
    },
    // @ts-ignore
    ...params
  ) {
    // @ts-ignore
    super(devMessage, ...params)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }

    this.code = code
    this.userMessage = userMessage
    this.devMessage = devMessage
    this.data = data
    this.statusCode = statusCode
  }
}

export class ActionError {
  statusCode: number
  code: string
  userMessage: string
  devMessage?: string
  data?: object
  error?: boolean

  constructor(
    errorPayload: unknown,
    {
      toast = true,
      toastLevel = 'error',
      log = true,
    }: {
      log?: boolean
      toast?: boolean
      toastLevel?: 'error' | 'warn'
    } = {}
  ) {
    const data = errorPayload as {
      statusCode: number
      code: string
      userMessage: string
      devMessage?: string
      data?: object
    }

    this.error = true
    this.code = data.code || 'UNKNOWN'
    this.userMessage =
      data.userMessage ||
      'Leider ist etwas schief gelaufen... Bitte versuche es erneut oder kontaktiere uns.'
    this.devMessage = data.devMessage || ''
    this.data = data.data || {}
    this.statusCode = data.statusCode || 400

    if (log) {
      logger.trace(this)
    }

    if (toast) {
      showToast[toastLevel](this.userMessage)
    }
  }
}

export function isServerActionError<T>(result: T | ActionError): result is ActionError {
  return (result as ActionError).error === true
}

export async function execServerAction<T>(
  action: Promise<T>,
  {
    returnError = true,
    ...opts
  }: {
    log?: boolean
    toast?: boolean
    toastLevel?: 'error' | 'warn'
    shouldRefresh?: boolean
    returnError?: boolean
  } = {}
): Promise<Exclude<T, ServiceErrorResponse>> {
  try {
    const result = await action

    // @ts-ignore
    if (result?.error) {
      throw new ActionError(result, opts) as ActionError
    }

    return result as Exclude<T, ServiceErrorResponse>
  } catch (error) {
    if (isServerActionError(error)) throw error
    throw new ActionError(error, opts) as ActionError
  }
}

export async function callApi<Input, Output>(path: string, payload: Input): Promise<Output> {
  const response = await fetch('/api/service' + path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()

  if (response.status >= 400) {
    throw new ApiError(data)
  }

  return data
}

export function useCallApi<Input, Output>(
  path: string,
  data: Input
): {
  result: Output | null
  error: ApiError | null
  isLoading: boolean
  refetch: () => void
  modifyResult: (newResult: Output) => void
} {
  const [result, setResult] = React.useState<Output | null>(null)
  const [error, setError] = React.useState<ApiError | null>(null)
  const [isLoading, setIsLoading] = React.useState(true)

  function submitRequest() {
    setIsLoading(true)

    callApi<Input, Output>(path, data)
      .then(setResult)
      .catch(setError)
      .finally(() => setIsLoading(false))
  }

  const dataString = JSON.stringify(data)
  React.useEffect(() => {
    submitRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, dataString])

  function refetch() {
    submitRequest()
  }

  function modifyResult(newResult: Output) {
    setResult(newResult)
  }

  return { result, error, isLoading, refetch, modifyResult }
}
