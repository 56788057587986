import Image from 'next/image'

type Props = {
  size?: number
  style?: React.CSSProperties
}

export function Logo({ size = 300, style, ...props }: Props) {
  return (
    <Image
      src='/assets/global/logo@4x.png'
      priority
      width='0'
      height='0'
      sizes={'100vw'}
      style={{ width: size, height: 'auto', ...style }}
      alt='PitchGuru Logo'
      {...props}
    />
  )
}
