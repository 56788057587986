// eslint-disable-next-line
import axios, { AxiosProgressEvent } from 'axios'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { FileUploadInput, FileUploadOutput } from '~/services/file/upload'
import { callApi } from './callApi'

export async function uploadFile(file?: File, onProgress?: (progress: number) => void) {
  if (!file) return null
  const filename = file.name
  const fileType = file.type

  onProgress?.(5)
  const { uploadUrl, fields, previewUrl, location } = await callApi<FileUploadInput, FileUploadOutput>(
    '/file/upload',
    { fileType }
  )

  onProgress?.(10)

  const formData = new FormData()

  Object.entries({ ...fields, file }).forEach(([key, value]) => {
    formData.append(key, value as unknown as string)
  })

  const upload = await axios.post(uploadUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      onProgress?.(Math.max(10, _.round((progressEvent.progress || 0) * 100)))
    },
  })

  if (upload.status < 400) {
    return {
      previewUrl,
      location,
    }
  } else {
    toast.error('Upload ist fehlgeschlagen. Bitte versuche es erneut.')
    throw new Error('Upload failed.')
  }
}
