import Image from 'next/image'
import closeIcon from './close-square.svg'

const CloseButton = ({
  onClick,
  className,
  size = 20,
}: {
  onClick?: () => void
  className?: string
  size?: number
}) => (
  <div
    style={{ width: size * 1.1 + 10, height: size * 1.1 + 10 }}
    className={'rounded-full flex justify-center items-center  bg-offset cursor-pointer ' + className}
    onClick={onClick}
  >
    <Image src={closeIcon.src} height={size} width={size} alt='Close'></Image>
  </div>
)

export default CloseButton
