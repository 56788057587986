import classNames from 'classnames'
import { MoonLoader } from 'react-spinners'

type Props = { size?: number; className?: string } & React.HTMLAttributes<HTMLDivElement>

const LoadingPage = ({ size = 36, className, children, ...props }: Props) => {
  return (
    <div
      className={classNames('flex flex-col w-full h-full justify-center items-center', className)}
      {...props}
    >
      <MoonLoader size={size} />
      {children}
    </div>
  )
}

export default LoadingPage
