import classNames from 'classnames'

type Props = {
  progress: number // 0-100
  className?: string
  progressFillClassName?: string
  progressStyle?: React.CSSProperties
  progressIndicatorContent?: React.ReactNode
  showProgressIndicator?: boolean
}

const ProgressBar = ({
  progress,
  className,
  progressFillClassName = ' bg-gradient-to-r from-[#d3fcfc] via-[#a090be] to-[#ec424e]',
  progressStyle,
  progressIndicatorContent,
  showProgressIndicator = true,
}: Props) => {
  return (
    <div className={classNames('bg-gray-400 h-[2px] w-full mt-3 relative', className)}>
      {progressIndicatorContent !== undefined && (
        <div
          className={classNames(
            'absolute flex flex-col items-center -translate-x-[24px] transition-opacity',
            {
              'opacity-0': !showProgressIndicator,
            }
          )}
          style={{ bottom: 0, left: `${progress}%` }}
        >
          <div
            className='bg-black text-white rounded-full flex items-center justify-center'
            style={{ width: 44, height: 44 }}
          >
            <span className='text-sm'>{progressIndicatorContent}</span>
          </div>
          <div className='bg-black' style={{ width: 2, height: 17 }}></div>
        </div>
      )}
      <div
        className={classNames('h-[6px] rounded absolute left-0', progressFillClassName)}
        style={{
          top: -2,
          width: `${progress}%`,
          ...progressStyle,
        }}
      ></div>
    </div>
  )
}

export default ProgressBar
