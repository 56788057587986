import _ from 'lodash'
import welcomeIllustration from '~/../public/assets/home/welcome.svg'
import { Logo, Pill } from '~/components'
import { UserRole } from '~/models'

type Props = { children: React.ReactNode; userMode?: UserRole | null; pillOverride?: string }

export function IllustrationSplitView({ children, userMode, pillOverride }: Props) {
  return (
    <div className='flex flex-wrap items-stretch h-screen w-screen '>
      <div className='w-1/2 h-full grow bg-white rounded flex flex-col p-24 items-center'>
        <div className='flex-nogrow w-[400px] flex flex-nowrap justify-between items-center'>
          <Logo size={135} />
          {(userMode || pillOverride) && <Pill>{pillOverride || _.capitalize(userMode!)}</Pill>}
        </div>
        <div className='flex-grow w-[400px] flex flex-col justify-center'>{children}</div>
      </div>
      <div
        className='w-1/2 h-full bg-cover bg-center grow bg-no-repeat bg-gray'
        style={{ backgroundImage: `url(${welcomeIllustration.src})` }}
      ></div>
    </div>
  )
}
