'use client'

import classNames from 'classnames'
import { ArrowDown2, ArrowUp2 } from 'iconsax-react'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { LoadingPage } from '~/compositions'
import Pagination, { PaginationProps } from './Pagination'

export type TableColumn<RecordType> = {
  key: string
  title: string
  className?: string
  // NOTE: if render is not defined, item[column.key] will be used
  render?: (record: RecordType, index?: number, array?: RecordType[], metadata?: any) => React.ReactNode
  headerClassName?: string
  cellClassName?: string
  style?: React.CSSProperties
  sortKey?: string
}

export type GenericSortType = { [key: string]: -1 | 1 | undefined }

type TableProps<RecordType> = {
  columns: TableColumn<RecordType>[]
  data: RecordType[]
  tableClassName?: string
  isLoading?: boolean
  sort?: GenericSortType
  onSortChange?: (sort: GenericSortType) => void
  noDataCaption?: string
  pagination?: PaginationProps
  keySelector?: (record: RecordType, index?: number) => string
  metadata?: any
}

function Table<RecordType>({
  columns,
  data,
  tableClassName,
  isLoading,
  noDataCaption = 'Wir haben keine Daten gefunden...',
  pagination,
  keySelector,
  sort,
  onSortChange,
  metadata,
}: TableProps<RecordType>) {
  const defaultColumnClassName = 'w-20 py-5'

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  const bottomContent = isLoading ? (
    <LoadingPage size={24} />
  ) : data?.length === 0 ? (
    <p className='opacity-50'>{noDataCaption}</p>
  ) : null

  return (
    <>
      <table className={classNames(tableClassName, 'p-4 w-full')}>
        <thead>
          <tr className='flex text-xs' style={{ color: 'rgb(155, 155, 155)' }}>
            {columns.map((column, index) => {
              const isSortable = column.sortKey !== undefined
              const isSorted = isSortable && sort?.[column.sortKey as string] !== undefined

              const onHeaderClick = !isSortable
                ? () => {}
                : () => {
                    let sortValue = undefined
                    if (!isSorted) sortValue = 1
                    if ((sort?.[column.sortKey as string] as number) === 1) sortValue = -1
                    const newSort = { [column.sortKey as string]: sortValue }
                    onSortChange?.(newSort as GenericSortType)
                  }

              return (
                <th
                  key={index}
                  className={classNames(
                    column.headerClassName,
                    defaultColumnClassName,
                    column.className,
                    'text-left font-normal py-0 flex items-center',
                    { 'cursor-pointer': isSortable }
                  )}
                  style={{ ...column.style, paddingTop: 0, paddingBottom: 0 }}
                  onClick={onHeaderClick}
                >
                  {column.title}
                  {isSortable && (
                    <div className='flex items-center ml-2'>
                      {(!isSorted || [-1].includes(sort?.[column.sortKey as string] as number)) && (
                        <ArrowUp2 size={12} />
                      )}
                      {(!isSorted || [1].includes(sort?.[column.sortKey as string] as number)) && (
                        <ArrowDown2 size={12} />
                      )}
                    </div>
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <>
          {!bottomContent && (
            <tbody>
              {data.map((item: RecordType, index, array) => {
                return (
                  <tr
                    // @ts-ignore
                    key={keySelector ? keySelector(item, index) : _.has(item, '_id') ? item._id : index}
                    className={classNames('flex items-center text-xs', {
                      'border-b border-grau-100': index < array.length - 1,
                    })}
                  >
                    {columns.map((column: TableColumn<RecordType>) => {
                      return (
                        <td
                          key={column.key}
                          className={classNames(
                            column.cellClassName,
                            defaultColumnClassName,
                            column.className
                          )}
                          style={column.style}
                        >
                          {column.render
                            ? column.render(item, index, array, metadata)
                            : // @ts-ignore
                              item[column.key]}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          )}
        </>
      </table>
      {bottomContent && <div className='flex justify-center items-center h-48'>{bottomContent}</div>}
      {!isLoading && pagination && data.length > 1 && <Pagination {...pagination} />}
    </>
  )
}

export default Table
