import classNames from 'classnames'
import { Icon } from 'iconsax-react'

type ToggleSlideOption = {
  id: string
  title: string
  subTitle?: string
  icon?: Icon
  disabled?: boolean
}

type Props = {
  options: ToggleSlideOption[]
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  readOnly?: boolean
}

const ToggleSlide = ({ options, value, onChange, disabled, readOnly }: Props) => {
  const selectedIndex = options.findIndex(option => option.id === value)

  return (
    <div
      className={classNames('p-2 rounded bg-[#F1F1F1]', {
        'pointer-events-none opacity-70': disabled,
        'pointer-events-none opacity-85': readOnly,
      })}
    >
      <div className='relative  flex item-center'>
        <div
          style={{ width: `${100 / options.length}%`, transform: `translateX(${selectedIndex * 100}%)` }}
          className={classNames(
            'h-full rounded absolute left-0 top-0 bg-white z-10 transition-transform duration-300'
          )}
        />
        {options.map(option => {
          return (
            <SlideBtn
              key={option.id}
              isActive={value === option.id}
              title={option.title}
              subTitle={option.subTitle}
              onClick={() => onChange(option.id)}
              readOnly={!!disabled || !!option.disabled}
              icon={option.icon}
            />
          )
        })}
      </div>
    </div>
  )
}

const SlideBtn = ({
  isActive,
  title,
  subTitle,
  onClick,
  readOnly,
  icon: Icon,
}: {
  isActive: boolean
  title: string
  subTitle?: string
  onClick: () => void
  readOnly: boolean
  icon?: Icon
}) => {
  return (
    <div
      className={classNames('flex-1 py-3 px-2 flex flex-col items-center text-center relative z-20', {
        'text-[#0080FF]': isActive,
        'cursor-pointer': !readOnly,
        'cursor-default': readOnly,
        'text-gray-300': readOnly,
      })}
      onClick={readOnly ? () => {} : onClick}
    >
      {Icon && <Icon className='mb-2' size='24' />}
      <h3 className='text-lg'>{title}</h3>
      {subTitle && <p className='text-sm'>{subTitle}</p>}
    </div>
  )
}

export default ToggleSlide
