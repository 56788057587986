import classNames from 'classnames'
import { Icon, TickCircle, type IconProps } from 'iconsax-react'
import React from 'react'
import tickFilled from './tick-filled.svg'

const ToggleIcon = React.forwardRef(
  (
    {
      isActive,
      Icon,
      className,
      size = '16',
      variant,
      disabled,
      ...props
    }: React.HTMLAttributes<HTMLDivElement> & {
      size?: string
      className?: string
      isActive: boolean
      variant?: IconProps['variant']
      Icon: Icon
      disabled?: boolean
    },
    ref
  ) => {
    const isTick = Icon === TickCircle

    const sharedClassNames = {
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
      'opacity-60': disabled,
    }

    if (isTick) {
      const adjustedSize = parseInt(size) + 12
      if (isActive) {
        return (
          <img
            ref={ref as any}
            src={tickFilled.src}
            alt='tick filled'
            style={{ width: adjustedSize, height: adjustedSize }}
            className={classNames(className, sharedClassNames)}
            {...props}
          />
        )
      } else {
        return (
          <div
            ref={ref as any}
            className={classNames(
              'border-2 border-black rounded-full cursor-pointer ' + className,
              sharedClassNames
            )}
            style={{ width: adjustedSize, height: adjustedSize }}
            {...props}
          />
        )
      }
    }

    return (
      <div
        ref={ref as any}
        className={classNames(
          className,
          'rounded-full p-[6px] transition border-transparent cursor-pointer',
          { 'bg-black': isActive },
          sharedClassNames
        )}
        {...props}
      >
        <Icon variant={variant} size={size} color={isActive ? 'white' : 'black'} />
      </div>
    )
  }
)

export default ToggleIcon
