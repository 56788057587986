'use client'

import classNames from 'classnames'
import { ArrowDown2, ArrowUp2 } from 'iconsax-react'
import { useEffect, useRef, useState } from 'react'

type Props = {
  label?: string
  placeholder: string
  id?: string
  className?: string
  name?: string
  value: string
  hasDropDown?: boolean
  onChange?: (e: any) => void
  dropdownOptions?: string[]
  readOnly?: boolean
  type?: string
  icon?: React.ReactNode
  inputClassName?: string
} & Partial<React.InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement>>

export default function Input({
  label,
  placeholder,
  hasDropDown,
  className,
  onChange,
  value,
  dropdownOptions,
  name,
  readOnly,
  type,
  icon,
  inputClassName,
  ...rest
}: Props) {
  const [isActivated, setIsActivated] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleDropdownChange = ({ option }: { option: string }) => {
    if (!onChange) return
    onChange({ target: { name, value: option } })
    setIsActivated(!isActivated)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActivated(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  const inputProps = {
    className: classNames(
      'z-0 px-2 py-4 placeholder:grau-400 border rounded-md shadow-sm focus:outline-none focus:ring-black focus:border-black w-full disabled:text-grau-600',
      inputClassName
    ),
    placeholder,
    name,
    onChange,
    value,
    readOnly,
    disabled: readOnly,
    type,
    ...rest,
  }

  return (
    <div className={`mb-4 ${className} w-full`}>
      <label className='block mb-2 ml-1 text-grau-700 text-sm' htmlFor={rest.id}>
        {label}
      </label>
      <div className='relative'>
        {type === 'textarea' ? <textarea {...inputProps} /> : <input {...inputProps} />}
        <div className='absolute top-1/2 right-2 translate-y-[-50%]'>
          {readOnly
            ? null
            : hasDropDown && (
                <button
                  onClick={e => {
                    e.preventDefault()
                    setIsActivated(!isActivated)
                  }}
                  className='bg-grau-100 rounded-full w-5 h-5 flex justify-center items-center flex-shrink-0'
                >
                  {isActivated ? (
                    <ArrowUp2 size='16' color='black' />
                  ) : (
                    <ArrowDown2 size='16' color='black' />
                  )}
                </button>
              )}
          {icon && icon}
        </div>
        {isActivated && (
          <div
            ref={dropdownRef}
            className='absolute z-50 top-[60px] overflow-y-hidden w-full left-0 border-2 rounded-2xl shadow-lg border-grau-100 bg-white'
          >
            <div className='max-h-40 overflow-y-auto'>
              {dropdownOptions?.map((option, index) => (
                <div
                  key={index}
                  className='pl-3 py-3 text-sm cursor-pointer hover:bg-gray-100'
                  onClick={e => handleDropdownChange({ option })}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
