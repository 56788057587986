import classNames from 'classnames'
import React from 'react'

type Props = {
  children?: React.ReactNode
  title?: string
  separator?: boolean
  style?: React.CSSProperties
  className?: string
  headerElement?: React.ReactNode
  bodyClassName?: string
}

export const Card = ({
  title,
  separator,
  children,
  style,
  className,
  headerElement,
  bodyClassName,
}: Props) => {
  return (
    <div className={classNames('rounded bg-white mb-4 flex flex-col', className)} style={style}>
      {title && (
        <div
          className={classNames('flex justify-between items-center', {
            'p-7 border-offset': true,
            'border-b-2': separator,
          })}
        >
          <h2 className='text-2xl'>{title}</h2>
          {headerElement}
        </div>
      )}
      <div
        className={classNames(bodyClassName, {
          'p-7': true,
          'pt-5': title && separator,
          'pt-0': title && !separator,
        })}
      >
        {children}
      </div>
    </div>
  )
}
