'use client'

import { ArrowLeft, ArrowRight } from 'iconsax-react'
import Button from '../Button'

export type PaginationProps = {
  limit: number
  skip: number
  allCount: number
  onChange: (limit: number, skip: number) => void
}

const Pagination = ({ limit, skip, allCount, onChange }: PaginationProps) => {
  return (
    <div className='w-full flex flex-col items-center'>
      <div className='flex items-center justify-center font-sm'>
        <Button
          className='py-1 px-3 mr-3'
          style={{ paddingTop: 3, paddingBottom: 3, border: 'none' }}
          inverted
          disabled={skip === 0}
          onClick={() => onChange(limit, skip - limit)}
        >
          <ArrowLeft />
        </Button>
        Einträge
        <span className='font-semibold mx-1'>{skip + 1}</span>
        bis
        <span className='font-semibold mx-1'>{Math.min(skip + limit, allCount)}</span>
        von
        <span className='font-semibold mx-1'>{allCount}</span>
        <Button
          className='py-1 px-3 ml-3'
          style={{ paddingTop: 3, paddingBottom: 3, border: 'none' }}
          inverted
          disabled={limit + skip >= allCount}
          onClick={() => onChange(limit, skip + limit)}
        >
          <ArrowRight />
        </Button>
      </div>
      <div className='flex items-center justify-center'>
        <div style={{ width: 40 }}></div>
      </div>
    </div>
  )
}

export default Pagination
