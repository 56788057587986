'use client'
import { createPortal } from 'react-dom'

type Props = {
  children: React.ReactNode
}

const Modal = ({ children }: Props) => {
  return (
    <>
      {createPortal(
        <div className='animate-fade-in flex justify-center items-center fixed w-screen h-screen top-0 left-0 bg-[rgba(0,0,0,0.6)] z-30'>
          <div className='animate-move-up'>{children}</div>
        </div>,
        document.body
      )}
    </>
  )
}

export default Modal
