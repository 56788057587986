'use client'

import { TooltipPlacement, TooltipProps as TTProps, Tooltip as UiTooltip } from '@nextui-org/react'

export type TooltipProps = TTProps & {
  delayDuration?: number
  children: React.ReactNode
  side?: TooltipPlacement
  hint?: string
  disabled?: boolean
}
export function Tooltip({
  children,
  side,
  hint,
  delayDuration = 150,
  disabled,
  ...tooltipProps
}: TooltipProps) {
  if (disabled) return <>{children}</>

  return (
    <UiTooltip showArrow={true} delay={delayDuration} placement={side} content={hint} {...tooltipProps}>
      {children}
    </UiTooltip>
  )
}
