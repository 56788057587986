'use client'
import classNames from 'classnames'
import React from 'react'
import { SyncLoader } from 'react-spinners'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode
  className?: string
  icon?: React.ReactNode
  disabled?: boolean
  inverted?: boolean
  isLoading?: boolean
  small?: boolean
}

const Button = React.forwardRef(
  (
    { className, icon, children, disabled = false, inverted = false, isLoading, small, ...props }: Props,
    ref
  ) => {
    return (
      <button
        ref={ref as any}
        className={classNames(
          className,
          `rounded-sm text-[14px] flex items-center justify-center relative`,
          {
            'opacity-25 cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
            'text-white bg-black': !inverted,
            'bg-transparent text-black border-2 border-grau-300': inverted,
            'pointer-events-none': isLoading,
            'py-1.5 px-2.5': small,
            'py-3 px-5': !small,
          }
        )}
        disabled={disabled}
        {...props}
      >
        <span className={classNames('flex items-center', { 'opacity-0': isLoading })}>
          {children}
          {icon && <span className='pl-2'>{icon}</span>}
        </span>
        {isLoading && (
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center'>
            <SyncLoader size={4} color={inverted || disabled ? 'rgb(72, 72, 72)' : 'white'} />
          </div>
        )}
      </button>
    )
  }
)

export default Button
