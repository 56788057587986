import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & { children?: React.ReactNode; className?: string }

const Pill = ({ children, className, ...props }: Props) => {
  return (
    <span className={`py-3 px-4 bg-black text-white rounded-full text-xs ${className}`} {...props}>
      {children}
    </span>
  )
}

export default Pill
