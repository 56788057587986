'use client'
import Image from 'next/image'
import { useParams, useRouter } from 'next/navigation'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Card, CloseButton, Modal as ModalWrapper } from '~/components'
import { OrderDraftInput, OrderDraftOutput } from '~/services/order/draft'
import { ApiError, callApi } from '~/utils/client/callApi'
import { uploadFile } from '~/utils/client/upload'
import UploadLoader from './Loader'

import { Convert3DCube, GalleryAdd } from 'iconsax-react'
import log from 'loglevel'
import ToggleSlide from '~/components/ToggleSlide'
import DropZone from '~/compositions/DropZone'
import { UploadPresentationInput, UploadPresentationOutput } from '~/services/order/upload-presentation'
import pptIcon from './assets/ppt.svg'

type Props = {
  children: React.ReactNode
  orderId?: string
  hint?: string
  refreshOnCompleted?: boolean
}
/**
 *
 * @param orderId string - when not present, a new order will be created. If passed, the presentation will be added to the order
 *
 * @returns
 */
const PresentationUpload = ({ children, orderId, hint, refreshOnCompleted }: Props) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <>
        {React.Children.map(children, child => {
          // @ts-ignore
          return React.cloneElement(child, {
            onClick: () => {
              setOpen(true)
            },
          })
        })}
        {isOpen && (
          <PresentationUploadModal
            isOpen={isOpen}
            close={() => setOpen(false)}
            orderId={orderId}
            hint={hint}
            refreshOnCompleted={!!refreshOnCompleted}
          />
        )}
      </>
    </>
  )
}

export default PresentationUpload

export const PresentationUploadModal = ({
  close,
  isOpen,
  orderId,
  hint,
  refreshOnCompleted,
  mode = 'default',
  interMode: givenInterMode,
  currentSlideCount = 0,
  disableRevisionUpload,
}: {
  close: () => void
  isOpen: boolean
  orderId?: string
  hint?: string
  refreshOnCompleted: boolean
  mode?: 'default' | 'inter'
  currentSlideCount?: number
  disableRevisionUpload?: boolean
  interMode?: 'addSlides' | 'newPres'
}) => {
  const [progress, setProgress] = useState(0)

  const router = useRouter()
  const params = useParams()

  const [interMode, setInterMode] = useState<'addSlides' | 'newPres'>('addSlides')

  useEffect(() => {
    if (givenInterMode) setInterMode(givenInterMode)
  }, [givenInterMode])

  useEffect(() => {
    if (!isOpen) setProgress(0)
  }, [isOpen])

  const onDrop = useCallback(
    async (files: File[]) => {
      setProgress(1)
      const file = files?.[0]
      const result = await uploadFile(file, progress => setProgress((progress / 100) * 47))

      let p = 47

      const interval = setInterval(() => {
        p = p + (100 - p) / 4
        setProgress(p)
      }, 2500)

      let redirectUrl
      const queryParams: { [key: string]: string } = {}
      try {
        let order
        if (orderId) {
          order = await callApi<UploadPresentationInput, UploadPresentationOutput>(
            '/order/upload-presentation',
            {
              orderId,
              filename: file.name,
              tmpLocation: result!.location,
              interMode: mode === 'inter' ? interMode : undefined,
            }
          )

          if (interMode === 'addSlides') {
            queryParams.shouldPurchaseMore = 'true'
            queryParams.lastSlideIndex = (currentSlideCount - 1).toString()
          }
        } else {
          order = await callApi<OrderDraftInput, OrderDraftOutput>('/order/draft', {
            filename: file.name,
            tmpLocation: result!.location,
          })

          redirectUrl = `/u/org/${params.orgId}/order/${order._id}`
        }
        clearInterval(interval)
        setProgress(100)
        toast.success('Präsentation erfolgreich hochgeladen!')

        if (refreshOnCompleted) {
          queryParams.revalidate = Date.now().toString()
          router.push(`?${new URLSearchParams(queryParams).toString()}`)
        }
        await new Promise(resolve => setTimeout(resolve, 3000))
      } catch (err) {
        if (err instanceof ApiError) {
          toast.error(err.userMessage)
        } else {
          log.error(err)
          toast.error('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.')
        }
        clearInterval(interval)
      } finally {
        if (redirectUrl) router.push(redirectUrl)
        else close()
      }
    },
    [router, params, orderId, close, refreshOnCompleted, interMode, mode, currentSlideCount]
  )

  if (!isOpen) return null

  return (
    <ModalWrapper>
      <Card
        separator
        title={mode === 'default' ? 'Präsentation importieren' : 'Weitere Dateien hochladen'}
        className='w-[500px]'
        headerElement={<CloseButton onClick={close} />}
      >
        {mode === 'inter' && (
          <ToggleSlide
            value={interMode}
            onChange={interMode => setInterMode(interMode as 'addSlides' | 'newPres')}
            options={[
              {
                id: 'addSlides',
                icon: GalleryAdd,
                title: 'Folien hinzufügen',
                subTitle: 'Fügen Sie der Bestellung weitere Folien hinzu',
              },
              {
                id: 'newPres',
                icon: Convert3DCube,
                title: 'Status Quo hochladen',
                subTitle: 'Laden Sie einen Zwischenstand hoch',
                disabled: disableRevisionUpload,
              },
            ]}
          />
        )}
        {disableRevisionUpload && (
          <>
            <p className='text-xs mt-2 text-center opacity-50'>
              Ein Zwischenstand kann nur hochgeladen werden, wenn es bereits Designanpassungen gab.
            </p>
            <hr className='my-4' />
          </>
        )}
        <p className='my-4'>Folgende Dateitypen können Sie hochladen:</p>
        <div
          className='rounded p-4 flex flex-col items-center mb-8'
          style={{ background: 'rgba(240, 149, 115, 0.25)' }}
        >
          <Image className='mb-2' src={pptIcon.src} height={50} width={50} alt='Powerpoint' />
          <p className='text-sm text-gray-600'>.pptx</p>
        </div>
        {progress === 0 && (
          <>
            <DropZone
              isLoading={false}
              onDrop={onDrop}
              callToAction='Präsentation hier per Drag & Drop importieren'
            />
            {hint && <p className='text-center mt-3 text-grau-700 text-sm'>{hint}</p>}
          </>
        )}
        {progress > 0 && <UploadLoader progress={progress} />}
      </Card>
    </ModalWrapper>
  )
}
