import { ReceiveSquare, TickSquare } from 'iconsax-react'
import { useDropzone } from 'react-dropzone'
import { Button } from '~/components'
import { MoonLoader } from 'react-spinners'

type Props = {
  onDrop: (files: File[]) => void
  isLoading: boolean
  height?: number
  callToAction?: string
  className?: string
}

const Drop = ({
  onDrop,
  isLoading,
  height = 200,
  callToAction = 'Datei hier per Drag & Drop importieren',
  className,
}: Props) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    },
  })

  const inputProps = getInputProps()
  return (
    <>
      <div
        {...getRootProps()}
        className={`rounded flex flex-col items-center justify-center min-h-[200px]  bg-offset cursor-pointer ${className}`}
        style={{ height }}
      >
        <input {...inputProps}></input>
        {isDragActive ? (
          <>
            <div className='rounded flex flex-col items-center justify-center bg-black p-2 mb-2'>
              <TickSquare size='30' color='#fff' />
            </div>
            <p className='text-gray-600'>Hier ablegen! </p>
          </>
        ) : (
          <>
            {isLoading ? (
              <MoonLoader size={30} color='black' />
            ) : (
              <>
                <div className='rounded flex flex-col items-center justify-center bg-black p-2 mb-2'>
                  <ReceiveSquare size='30' color='#fff' />
                </div>
                <p className='text-gray-600'>{callToAction}</p>
              </>
            )}
          </>
        )}
      </div>
      <Button
        onClick={() => {
          open()
        }}
        className='w-full mt-4'
      >
        Datei manuell auswählen
      </Button>
    </>
  )
}

export default Drop
