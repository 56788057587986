import Image from 'next/image'
import card1 from './assets/card-1-empty.svg'
import card2 from './assets/card-2-empty.svg'
import card3 from './assets/card-3-empty.svg'
import card4 from './assets/card-4-empty.svg'

const cards = [card1, card2, card3, card4, card1, card2]

type Props = {
  progress: number // 0-100
}

const UploadLoader = ({ progress }: Props) => {
  return (
    <div className='w-full'>
      <div className='flex flex-nowrap justify-between items-center mb-10 mt-14'>
        {cards.map((card, index, cards) => {
          const isActive = index < progress / (cards.length * 3)

          return (
            <Image
              src={card.src}
              key={index}
              width={60}
              height={40}
              alt='loading card'
              className='transition-opacity duration-500 ease-in-out'
              style={{ opacity: isActive ? 1 : 0.5 }}
            />
          )
        })}
      </div>
      <div className='flex flex-nowrap justify-start items-center mb-8'>
        <div
          className='bg-black transition-all duration-500 ease-in-out'
          style={{ height: 2, width: `${progress}%` }}
        ></div>
        <p className='ml-2 shrink-0'> {parseInt(progress.toString())} %</p>
      </div>
    </div>
  )
}

export default UploadLoader
